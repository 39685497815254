import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { instance } from "../../routes/axios";
import { showSnackbar } from "../../store/snackbarSlice";

export default function DrillsIdNav({ setDrill, setScores, setData, setLoading, csvPage }) {
  const { drillId, id } = useParams();
  const user = useSelector((state) => state.user);
  const baseUrl = `/users/${user?._id}/drill-history/${drillId}`;
  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    if (!user) return;
    if (csvPage) return;
    setLoading(true);
    try {
      const videos = await instance.post("/drill-upload-details", {
        _id: id,
      });
      const analysis = await instance.post("/drill-analysis", {
        userId: id,
      });
      const scores = await instance.post("/drill-scores", {
        userId: id,
      });
      const foundDrillAnalysis = analysis.data.data.find((x) => x.videoUploadsId === drillId);
      const foundScores = scores.data.data.find((x) => x.videoUploadsId === drillId);
      const foundVideo = videos.data.data.data.find((x) => x._id === drillId);
      setDrill(foundVideo);
      setData(foundDrillAnalysis);
      setScores(foundScores);
    } catch (err) {
      console.error(err);
      dispatch(showSnackbar({ message: "Something went wrong", severity: "error" }));
    } finally {
      setLoading(false);
    }
  }, [id, dispatch, drillId, user]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="userNav nav-drills">
      <div className="userNav__row main-content-px">
        <NavLink
          to={baseUrl}
          end>
          Overview
        </NavLink>
        <NavLink
          to={baseUrl + "/attempts"}
          className="todo">
          Drill Attempts
        </NavLink>
        <NavLink
          to={baseUrl + "/attempts"}
          className="todo">
          Event Log
        </NavLink>
        <NavLink to={baseUrl + "/files"}>CSV Files</NavLink>
      </div>
    </div>
  );
}
