import { NavLink } from "react-router-dom";
import Layout from "../components/Layout";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import AttachmentUpload from "../components/settings/AttachmentUpload";
import { useState, useEffect } from "react";
import { Input } from "rsuite";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../store/snackbarSlice";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import { Button } from "rsuite";
import AuthFormGroup from "../components/auth/AuthFormGroup";
import { instance } from "../routes/axios";
import { useSelector } from "react-redux";
import { setAdminData } from "../store/adminSlice";

export default function EditProfilePage() {
  const [isLoading, setLoading] = useState(false);
  const admin = useSelector((state) => state.admin);

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: admin?.firstName,
      lastName: admin?.lastName,
    },
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (admin) {
      reset({ firstName: admin.firstName, lastName: admin.lastName });
    }
  }, [admin]);

  const [attachmentsError, setAttachmentsError] = useState("");
  const [attachments, setAttachments] = useState([]);

  function handleFile(files) {
    setAttachmentsError("");
    for (let i = 0; i < files.length; i++) {
      if (files[i].size <= 5 * 1024 * 1024) {
        setAttachments((x) => [...x, files[i]]);
      } else {
        setAttachmentsError("File size exceeds the limit (5 MB)");
        setTimeout(() => {
          setAttachmentsError("");
        }, 2000);
      }
    }
  }

  function handleDelete(e, i) {
    setAttachments(attachments.filter((item, index) => index !== i));
    setAttachmentsError("");
  }

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files);
    }
  };

  async function onSubmit(data) {
    setLoading(true);
    try {
      const res = await instance.post("/edit-profile", { email: admin?.email, firstName: data.firstName, lastName: data.lastName });
      dispatch(showSnackbar({ message: "Profile has been successfully updated", severity: "success" }));
      dispatch(setAdminData(null));
    } catch (err) {
      dispatch(showSnackbar({ message: "Something went wrong...", severity: "error" }));
    } finally {
      setLoading(false);
    }
  }

  return (
    <Layout bodyColor="#eef2f6">
      <div className="main-content-px">
        <div className="editProfile page-block page-block-pd">
          <div className="editProfile__header">
            <NavLink to="/settings">
              <KeyboardArrowLeftIcon />
            </NavLink>
            Edit Profile
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="editProfile__group">
              <p className="editProfile__group-label">Profile Picture</p>
              <div className="max-width">
                <AttachmentUpload
                  handleFile={handleFile}
                  attachmentsError={attachmentsError}
                  attachments={attachments}
                  handleDelete={handleDelete}
                  handleChange={handleChange}
                />
              </div>
            </div>
            <div className="editProfile__group">
              <p className="editProfile__group-label">Your name</p>
              <div className="max-width editProfile__group-fields">
                <AuthFormGroup
                  label="First name"
                  error={errors.firstName}>
                  <Controller
                    name="firstName"
                    defaultValue={admin?.firstName}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        size="lg"
                        value={field.value}
                        onChange={(value) => field.onChange(value)}
                      />
                    )}
                  />
                </AuthFormGroup>
                <AuthFormGroup
                  label="Last name"
                  error={errors.lastName}>
                  <Controller
                    name="lastName"
                    defaultValue={admin?.lastName}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        size="lg"
                        value={field.value}
                        onChange={(value) => field.onChange(value)}
                      />
                    )}
                  />
                </AuthFormGroup>
              </div>
            </div>
            <div className="editProfile__group">
              <p className="editProfile__group-label">Email</p>
              <div className="max-width ">
                <Input
                  size="lg"
                  disabled
                  value={admin?.email}
                />
              </div>
            </div>
            <div className="editProfile__btns">
              <Button
                appearance="ghost"
                onClick={reset}
                className="outlined-btn min-width">
                Reset
              </Button>
              <Button
                appearance="primary"
                type="submit"
                loading={isLoading}
                className="outlined-btn black-btn min-width">
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}
