import TableCell from "@mui/material/TableCell";
import CheckIcon from "@mui/icons-material/Check";
import Checkbox from "@mui/material/Checkbox";

export default function CTableRowCheckbox({ _id, data, labelId, isItemSelected, selected, setSelected }) {
  const handleClickSelect = (event, id) => {
    event.stopPropagation();
    const selectedIndex = selected.findIndex((obj) => obj._id === id);
    const currentItem = data.find((x) => x._id === id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, currentItem);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  return (
    <TableCell padding="checkbox">
      <Checkbox
        checked={isItemSelected}
        onClick={(event) => event.stopPropagation()}
        onChange={(event) => handleClickSelect(event, _id)}
        inputProps={{
          "aria-labelledby": labelId,
        }}
        icon={<div className="custom-checkbox"></div>}
        checkedIcon={
          <div className="custom-checkbox">
            <CheckIcon />
          </div>
        }
      />
    </TableCell>
  );
}
