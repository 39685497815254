import moment from "moment";

const id_width = "140px";
const date_width = "200px";
const email_width = "30%";
const prompt_type_width = "200px";
const status_width = "150px";

export const usersTableHead = [
  { id: "name", numeric: false, sortable: true, label: "Full Name" },
  { id: "userId", numeric: false, sortable: true, label: "User Id", width: email_width },
  { id: "userDetails", numeric: false, sortable: true, label: "User details", width: id_width },
  { id: "createdDate", numeric: true, sortable: true, label: "Created date", width: date_width },
  { id: "updatedDate", numeric: false, sortable: true, label: "Updated date", width: date_width },
];

export const adminsTableHead = [
  { id: "firstName", numeric: false, sortable: true, label: "Full Name" },
  { id: "email", numeric: false, sortable: true, label: "Email", width: email_width },
  { id: "role", numeric: false, sortable: true, label: "Role", width: date_width },
  { id: "createdDate", numeric: true, sortable: true, label: "Created date", width: date_width },
  { id: "updatedDate", numeric: false, sortable: true, label: "Updated date", width: date_width },
];

export const promptTableHead = [
  { id: "_id", numeric: false, sortable: false, label: "Prompt ID", width: id_width },
  { id: "version", numeric: true, sortable: false, label: "Version", width: id_width },
  { id: "access_type", numeric: false, sortable: true, label: "Access type", width: prompt_type_width },
  { id: "prompt_type", numeric: false, sortable: true, label: "Prompt type", width: prompt_type_width },
  { id: "title", numeric: false, sortable: true, label: "Title", width: "15%" },
  { id: "description", numeric: false, sortable: false, label: "Content", width: "35%" },
  { id: "created_at", numeric: false, sortable: true, label: "Date", width: date_width },
];

export const allDrillsTableHead = [
  { id: "_id", numeric: false, sortable: true, label: "Drill ID", width: id_width },
  { id: "gameType", numeric: false, sortable: true, label: "Name", width: "50%" },
  { id: "uploadDate", numeric: false, sortable: true, label: "Uploaded Date", width: date_width },
  { id: "status", numeric: false, sortable: true, label: "Status", width: status_width },
  { id: "userId", numeric: false, sortable: true, label: "By User" },
];

export const metricsTableHead = [
  { id: "_id", numeric: false, sortable: true, label: "Prompt ID", width: id_width },
  { id: "version", numeric: true, sortable: true, label: "Version", width: id_width },
  { id: "gameType", numeric: false, sortable: true, label: "Game type" },
  { id: "name", numeric: false, sortable: true, label: "Name" },
];

export function getAdminNameLetters(firstName, lastName) {
  if (!firstName || !lastName) return "-";
  return firstName[0] + lastName[0];
}

export function formatToTwoDecimals(num) {
  return Math.floor(num * 100) / 100 + (num % 1 === 0 ? ".00" : "");
}

export function getScoreStatusClass(rawScore) {
  if (rawScore < 25) {
    return "bad";
  } else if (rawScore >= 25 && rawScore < 50) {
    return "average";
  } else if (rawScore >= 50 && rawScore < 75) {
    return "good";
  } else {
    return "very-good";
  }
}

export function formatStringClass(input) {
  if (!input) return "";
  return input
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

export function formatStringId(input) {
  if (!input) return "";
  const firstPart = input.slice(0, 5);
  const lastPart = input.slice(-5);
  return `${firstPart}...${lastPart}`;
}

export function formatDateTime(input) {
  if (!input) return "-";
  return moment(input).format("DD/MM/YYYY HH:mm");
}

// function formatString(input) {
//   if (!input) return;
//   const formatted = input.replace(/_/g, " ");
//   return formatted.charAt(0).toUpperCase() + formatted.slice(1);
// }

// const highlightText = (text) => {
//   if (!searchValue) return text;
//   const regex = new RegExp(`(${searchValue})`, "gi");
//   return text.replace(regex, '<span class="highlight">$1</span>');
// };
