import Layout from "../components/Layout";
import ConfigurationsNav from "../components/configurations/ConfigurationsNav";
import { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { instance } from "../routes/axios";
import { showSnackbar } from "../store/snackbarSlice";
import { Table, TableBody, TableCell, TableContainer } from "@mui/material";
import { promptTableHead } from "../components/users/helpers";
import CustomDialog from "../components/libs/CustomDialog";
import PromptUploadForm from "../components/configurations/PromptUploadForm";
import CTableHead from "../components/table/CTableHead";
import TableBodyLoading from "../components/table/TableBodyLoading";
import PromptTableToolbar from "../components/configurations/PromptTableToolbar";
import PromptTableExpandRow from "../components/configurations/PromptTableExpandRow";
import CTablePagination from "../components/table/CTablePagination";

export default function ConfigurationsPromptPage() {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("asc");
  const [sortBy, setSortBy] = useState("created_at");
  const [openUpload, setOpenUpload] = useState(false);
  const [editValues, setEditValues] = useState(null);
  const [editVersion, setEditVersion] = useState(null);
  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const res = await instance.post("/prompts/fetch-all", {
        orderBy,
        sortBy,
        page,
        limit,
      });
      setData(res.data.data.allPrompts);
      setTotalCount(res.data.data.totalCount);
    } catch (err) {
      dispatch(showSnackbar({ message: "Something went wrong", severity: "error" }));
    } finally {
      setLoading(false);
    }
  }, [dispatch, page, limit, orderBy, sortBy]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  function switchVersion(version, _id, openDialog) {
    const item = data.find((x) => x._id === _id);
    const currentVersionItem = item.history.find((x) => x.version === version);
    const changedItem = version ? currentVersionItem : item;
    console.log("changedItem");
    console.log(changedItem);
    setEditValues({
      title: changedItem.title,
      content: changedItem.content,
      prompt_type: changedItem.prompt_type,
      access_type: changedItem.access_type,
      _id: item._id,
      template_text: item.template_text,
      active_version: item.active_version,
      history: item.history,
      historyId: changedItem._id,
      specific_user: changedItem.specific_user,
    });
    if (openDialog) {
      setOpenUpload(true);
    }
  }

  return (
    <Layout>
      <ConfigurationsNav />
      <CustomDialog
        open={openUpload}
        title={editValues?._id ? `Edit Prompt (Version: ${editValues.active_version || "Default"})` : "Add Prompt"}
        close={() => setOpenUpload(false)}>
        <PromptUploadForm
          close={() => setOpenUpload(false)}
          fetchData={fetchData}
          editValues={editValues}
          _id={editValues?._id}
          isEdit
          switchVersion={switchVersion}
        />
      </CustomDialog>
      <>
        <PromptTableToolbar
          selected={selected}
          fetchData={fetchData}
          setSelected={setSelected}
          totalCount={totalCount}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 900 }}
            aria-labelledby="tableTitle">
            <CTableHead
              orderBy={orderBy}
              sortBy={sortBy}
              setOrderBy={setOrderBy}
              setSortBy={setSortBy}
              rowCount={data.length}
              numSelected={selected.length}
              setSelected={setSelected}
              data={data}
              headData={promptTableHead}>
              <TableCell width="40px"></TableCell>
              <TableCell width="40px"></TableCell>
            </CTableHead>
            <TableBody>
              {isLoading ? (
                <TableBodyLoading colSpan={promptTableHead.length + 3} />
              ) : (
                data.map((row, index) => {
                  return (
                    <PromptTableExpandRow
                      fetchData={fetchData}
                      row={row}
                      key={row._id + "-row"}
                      index={index}
                      selected={selected}
                      setSelected={setSelected}
                      data={data}
                      editPrompt={(e) => {
                        e.stopPropagation();
                        switchVersion(row.active_version, row._id, true);
                      }}
                      colSpan={promptTableHead.length + 3}
                    />
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <CTablePagination
          setPage={setPage}
          setLimit={setLimit}
          totalCount={totalCount}
          limit={limit}
          page={page}
        />
      </>
    </Layout>
  );
}
