import Layout from "../components/Layout";
import UserIdLayout from "../components/users/UserIdLayout";
import { DateRangePicker } from "rsuite";
import MultipleAutocomplete from "../components/libs/MultipleAutocomplete";
import { useState, useCallback, useEffect } from "react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { instance } from "../routes/axios";

// const typeOptions = [
//   { label: "Amateur", value: "Amateur" },
//   { label: "Intermediate", value: "Intermediate" },
//   { label: "Proficient", value: "Proficient" },
//   { label: "Expert", value: "Expert" },
// ];

// const data = [
//   { title: "Created account", text: "Date: 12.09.2022 / 15:37", checked: true },
//   { title: "Unlocked Amateur", text: "Date: 12.09.2022 / 15:37", checked: true },
//   { title: "Viewed on boarding video", text: "Date: 12.09.2022 / 15:37", checked: true },
//   { title: "Completed Amateur drills", text: "-", checked: false },
//   { title: "Skipped on boarding video", text: "Date: 12.09.2022 / 15:37", checked: true },
//   { title: "Engaged with Coach during level up", text: "Date: 12.09.2022 / 15:37", checked: true },
//   { title: "Interacted with coach during on boarding", text: "-", checked: true },
//   { title: "User returned next day", text: "Date: 12.09.2022 / 15:37", checked: false },
//   { title: "Uploaded first drill", text: "Date: 12.09.2022 / 15:37", checked: true },
//   { title: "Weekly user", text: "Date: 12.09.2022 / 15:37", checked: true },
//   { title: "Completed first initiation drill", text: "Date: 12.09.2022 / 15:37", checked: true },
//   { title: "Discussed workshops with coach", text: "-", checked: false },
//   { title: "Completed all 5 initiation drills", text: "Date: 12.09.2022 / 15:37", checked: true },
//   { title: "Gave favourite play location", text: "Date: 12.09.2022 / 15:37", checked: true },
// ];

function ActivityItem({ item }) {
  const { title, text, checked } = item;
  return (
    <div className="activity__item">
      <div>
        <p className="text-[16px] font-semibold text-greyTitle">{title}</p>
        <p className="text-greyTitle">{text}</p>
      </div>
      <div className={`activity__item-check ${checked ? "checked" : ""}`}>{checked ? <CheckIcon /> : <CloseIcon />}</div>
    </div>
  );
}

export default function ActivityPage() {
  const user = useSelector((state) => state.user);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const res = await instance.post("/activity", { userId: user?.userId });
      setData(res.data);
      setLoading(false);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Layout>
      <UserIdLayout>
        <div className="filters-block main-content-px">
          {/* <MultipleAutocomplete
            options={typeOptions}
            label="Type"
            onChange={(event, newValues) => setTypes(newValues)}
          /> */}
          <div>
            <p className="filter-label">Date</p>
            <DateRangePicker size="lg" />
          </div>
        </div>
        <div className="activity main-content-px">
          <div className="activity__list">
            {data?.length > 0 &&
              data.map((x) => {
                return (
                  <ActivityItem
                    key={x}
                    item={x}
                  />
                );
              })}
          </div>
        </div>
      </UserIdLayout>
    </Layout>
  );
}
