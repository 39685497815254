import { useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { adminsTableHead, formatDateTime } from "../users/helpers";
import AdminsTableToolbar from "./AdminsTableToolbar";
import TableBodyLoading from "../table/TableBodyLoading";
import CTableHead from "../table/CTableHead";
import CTableRowCheckbox from "../table/CTableRowCheckbox";
import CTablePagination from "../table/CTablePagination";

export default function AdminsTable({ isLoading, usersTotalCount, setLimit, limit, setPage, page, data, fetchData, setOrderBy, orderBy, sortBy, setSortBy }) {
  const [selected, setSelected] = useState([]);

  return (
    <>
      <AdminsTableToolbar
        selected={selected}
        fetchData={fetchData}
        setSelected={setSelected}
      />
      <TableContainer>
        <Table
          sx={{ minWidth: 900 }}
          aria-labelledby="tableTitle">
          <CTableHead
            setOrderBy={setOrderBy}
            setSortBy={setSortBy}
            data={data}
            setSelected={setSelected}
            headData={adminsTableHead}
            orderBy={orderBy}
            sortBy={sortBy}
            numSelected={selected.length}
            rowCount={data.length}
          />

          <TableBody>
            {isLoading ? (
              <TableBodyLoading colSpan={adminsTableHead.length + 1} />
            ) : (
              data.map((row, index) => {
                const isItemSelected = selected.some((obj) => obj._id === row._id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    tabIndex={-1}
                    aria-checked={isItemSelected}
                    selected={isItemSelected}
                    key={row._id}
                    sx={{ cursor: "pointer" }}>
                    <CTableRowCheckbox
                      _id={row._id}
                      data={data}
                      labelId={labelId}
                      isItemSelected={isItemSelected}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <TableCell>{row.firstName + " " + row.lastName}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.role}</TableCell>
                    <TableCell>{formatDateTime(row.createdDate)}</TableCell>
                    <TableCell>{formatDateTime(row.updatedDate)}</TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CTablePagination
        setPage={setPage}
        setLimit={setLimit}
        totalCount={usersTotalCount}
        limit={limit}
        page={page}
      />
    </>
  );
}
