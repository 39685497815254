import UserIdLayout from "../components/users/UserIdLayout";
import Layout from "../components/Layout";
import ChatsList from "../components/users/ChatsList";

export default function CoachChatsPage() {
  return (
    <Layout>
      <UserIdLayout>
        <ChatsList />
      </UserIdLayout>
    </Layout>
  );
}
