import UserHeader from "./UserHeader";
import UserNavigation from "./UserNavigation";
import { useEffect, useState } from "react";
import { instance } from "../../routes/axios";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { setUserData } from "../../store/userSlice";
import { useSelector } from "react-redux";
import { useCallback } from "react";

export default function UserIdLayout({ children, isDrill, name }) {
  const [isLoading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { id } = useParams();
  const user = useSelector((state) => state.user);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const res = await instance.post("/fetch-one-user", { _id: id });
      dispatch(setUserData(res.data));
      setLoading(false);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (!user) {
      fetchData();
    }
  }, [user, fetchData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="userId">
      {isLoading ? (
        <div className="main-content-px">
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <>
          <UserHeader
            isDrill={isDrill}
            name={name}
          />
          {!isDrill && <UserNavigation />}
          {children}
        </>
      )}
    </div>
  );
}
