import { Skeleton } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export default function TableBodyLoading({ colSpan }) {
  return (
    <TableRow>
      <TableCell colSpan={colSpan}>
        <div className="">
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>
      </TableCell>
    </TableRow>
  );
}
