import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  admin: null,
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setAdminData: (state, action) => action.payload,
  },
});

export const { setAdminData } = adminSlice.actions;

export default adminSlice.reducer;
