import UserIdLayout from "../components/users/UserIdLayout";
import Layout from "../components/Layout";
import VideocamIcon from "@mui/icons-material/Videocam";
import { NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useState, useEffect } from "react";
import { instance } from "../routes/axios";
import { showSnackbar } from "../store/snackbarSlice";
import DrillsIdNav from "../components/drills/DrillsIdNav";
import DownloadIcon from "@mui/icons-material/Download";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Skeleton } from "@mui/material";

export default function DrillHistoryIdCsvPage() {
  // const { drillId } = useParams();
  // const user = useSelector((state) => state.user);
  const [isLoading, setLoading] = useState(false);

  return (
    <Layout>
      <UserIdLayout isDrill>
        <DrillsIdNav csvPage />
        <div className="drillsCsv">
          <TableContainer>
            <Table sx={{ minWidth: 900 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Date Added</TableCell>
                  <TableCell>Size</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Drill Analysis</TableCell>
                  <TableCell>24/05/2024 - 15:20:03.408</TableCell>
                  <TableCell>15 MB</TableCell>
                  <TableCell>
                    <button className="drillsCsv__downloadBtn">
                      <DownloadIcon />
                    </button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Score Analysis</TableCell>
                  <TableCell>24/05/2024 - 15:20:03.408</TableCell>
                  <TableCell>15 MB</TableCell>
                  <TableCell>
                    <button className="drillsCsv__downloadBtn">
                      <DownloadIcon />
                    </button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Other files</TableCell>
                  <TableCell>24/05/2024 - 15:20:03.408</TableCell>
                  <TableCell>15 MB</TableCell>
                  <TableCell>
                    <button className="drillsCsv__downloadBtn">
                      <DownloadIcon />
                    </button>
                  </TableCell>
                </TableRow>
              </TableBody>
              {/* <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={adminsTableHead.length}>
                      <div className="main-content-px">
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  data.map((row, index) => {
                    return (
                      <TableRow
                        tabIndex={-1}
                        key={row._id}
                        sx={{ cursor: "pointer" }}>
                        <TableCell
                          component="th"
                          scope="row">
                          {row.firstName + " " + row.lastName}
                        </TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>{row.role}</TableCell>
                        <TableCell>{moment(row.createdDate).format("DD/MM/YYYY HH:mm")}</TableCell>
                        <TableCell>{moment(row.updatedDate).format("DD/MM/YYYY HH:mm")}</TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody> */}
            </Table>
          </TableContainer>
        </div>
      </UserIdLayout>
    </Layout>
  );
}
