import React from "react";
import Snackbar from "@mui/material/Snackbar";
import { useDispatch, useSelector } from "react-redux";
import { closeSnackbar } from "../../store/snackbarSlice";
import CheckIcon from "@mui/icons-material/Check";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";

export default function CustomSnackbar() {
  const dispatch = useDispatch();
  const { open, message, severity } = useSelector((state) => state.snackbar);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(closeSnackbar());
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      className={`snackbar ${severity}`}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      message={
        <div className="snackbar__content">
          <div className="snackbar__icon">
            {severity === "success" && <CheckIcon />}
            {severity === "error" && <PriorityHighIcon />}
          </div>
          <p className="snackbar__text">{message}</p>
        </div>
      }></Snackbar>
  );
}
