import { useForm, Controller } from "react-hook-form";
import { useState } from "react";
import { Button } from "rsuite";
import AuthFormGroup from "../auth/AuthFormGroup";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../store/snackbarSlice";
import { Input } from "rsuite";
import { instance } from "../../routes/axios";
import { useParams } from "react-router-dom";
import { setUserData } from "../../store/userSlice";
import { useSelector } from "react-redux";

export default function AddNoteForm({ close }) {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm();
  const user = useSelector((state) => state.user);
  const { userDetails } = user || [];

  async function onSubmit(data) {
    setLoading(true);
    try {
      const res = await instance.post("/create-note", { _id: user?._id, text: data.message });
      setLoading(false);
      dispatch(setUserData(null));
      close();
      reset();
      dispatch(showSnackbar({ message: "New note has been successfully added", severity: "success" }));
    } catch (err) {
      dispatch(showSnackbar({ message: "Something went wrong", severity: "error" }));
    } finally {
      setLoading(false);
    }
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="form">
      <div className="form__fields">
        <AuthFormGroup error={errors.message}>
          <Controller
            name="name"
            control={control}
            {...register("message", { required: true })}
            render={({ field }) => (
              <Input
                as="textarea"
                value={field.value}
                onChange={(value) => field.onChange(value)}
                placeholder="Add a short description..."
                rows={7}
              />
            )}
          />
        </AuthFormGroup>
      </div>
      <div className="c-dialog__btns">
        <Button
          appearance="ghost"
          onClick={close}
          className="outlined-btn">
          Cancel
        </Button>
        <Button
          appearance="primary"
          type="submit"
          loading={isLoading}
          className="outlined-btn black-btn min-width">
          Save
        </Button>
      </div>
    </form>
  );
}
