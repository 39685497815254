import { Avatar } from "rsuite";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Skeleton } from "@mui/material";
import { DateRangePicker } from "rsuite";
import { Input, InputGroup } from "rsuite";
import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../../helpers/useDebounce";
import { showSnackbar } from "../../store/snackbarSlice";
import { instance } from "../../routes/axios";
import { formatDateTime } from "./helpers";
import EmptyDataMessage from "../table/EmptyDataMessage";

export default function ChatsList({ hideFilter }) {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [dateRange, setDateRange] = useState([]);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const fetchData = useCallback(
    async (search = "", range = []) => {
      if (!user?._id) return;
      setLoading(true);
      try {
        const res = await instance.post("/get-coach-chats", {
          user: user._id,
          search: search,
          startDate: range ? range[0] : null,
          endDate: range ? range[1] : null,
        });
        setData(res.data.data.chats);
        setTotalCount(res.data.data.totalCount);
      } catch (err) {
        console.error(err);
        dispatch(showSnackbar({ message: "Something went wrong", severity: "error" }));
      } finally {
        setLoading(false);
      }
    },
    [user, dispatch]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const debouncedFetchData = useDebounce(fetchData, 500);

  const handleSearchChange = (value) => {
    setSearchValue(value);
    debouncedFetchData(value, dateRange);
  };

  const handleDateChange = (range) => {
    setDateRange(range);
    fetchData(searchValue, range);
  };

  const cleanDateChange = () => {
    setDateRange([]);
    fetchData(searchValue, []);
  };

  return (
    <>
      {!hideFilter && (
        <div className="filters-block chats__filters main-content-px">
          <div>
            <p className="filter-label">Search</p>
            <InputGroup
              size="lg"
              disabled={totalCount < 1}>
              <Input
                placeholder="Search messages..."
                onChange={(value) => handleSearchChange(value)}
              />
            </InputGroup>
          </div>
          <div>
            <p className="filter-label">Date</p>
            <DateRangePicker
              disabled={totalCount < 1}
              caretAs={null}
              size="lg"
              value={dateRange}
              onChange={handleDateChange}
              onClean={cleanDateChange}
            />
          </div>
        </div>
      )}
      <div className={`${!hideFilter ? "activity main-content-px" : ""}`}>
        {isLoading ? (
          <>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </>
        ) : (
          <div className="chats__list">
            {data.length > 0 ? (
              (hideFilter ? [...data].reverse() : data).map((x) => (
                <div
                  key={x._id}
                  className={`chats__item ${x.type === "human" ? "user-style" : ""}`}>
                  <Avatar
                    size="md"
                    circle
                    src={x.type === "human" ? user?.profilePicture : "https://dev-admin-restapi.aim-football.com/images/ai-coach.webp"}
                  />
                  <div>
                    <div className="chats__item-header">
                      <p>{x.type === "human" ? user?.name : "AI Coach"}</p>
                      <p>{formatDateTime(x.created_at)}</p>
                    </div>
                    <Markdown remarkPlugins={[remarkGfm]}>{x.content}</Markdown>
                  </div>
                </div>
              ))
            ) : (
              <EmptyDataMessage />
            )}
          </div>
        )}
      </div>
    </>
  );
}
