import { TableHead, TableRow } from "@mui/material";
import TableHeadCellList from "./TableHeadCellList";
import CTableHeadCheckbox from "./CTableHeadCheckbox";

export default function CTableHead({ setOrderBy, setSortBy, data, setSelected, children, headData, orderBy, sortBy, numSelected, rowCount }) {
  const handleRequestSort = (event, property) => {
    const isAsc = sortBy === property && orderBy === "asc";
    setOrderBy(isAsc ? "desc" : "asc");
    setSortBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelected([...data]);
      return;
    }
    setSelected([]);
  };

  return (
    <TableHead>
      <TableRow>
        {setSelected && (
          <CTableHeadCheckbox
            numSelected={numSelected}
            rowCount={rowCount}
            onSelectAllClick={handleSelectAllClick}
          />
        )}

        <TableHeadCellList
          createSortHandler={createSortHandler}
          data={headData}
          orderBy={orderBy}
          sortBy={sortBy}
        />
        {children}
      </TableRow>
    </TableHead>
  );
}
