import { useForm } from "react-hook-form";
import { useState } from "react";
import { InputAdornment, IconButton, Input } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button } from "rsuite";
import AuthFormGroup from "../auth/AuthFormGroup";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../store/snackbarSlice";
import axios from "axios";
import { instance } from "../../routes/axios";

export default function ChangePasswordForm({ close }) {
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  async function onSubmit(data) {
    setIsLoading(true);
    try {
      const res = await instance.post("/change-password", {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
        email: "khrystyna.chyzhyk@badbeat.com",
      });
      reset();
      close();
      dispatch(showSnackbar({ message: "Password has been successfully changed", severity: "success" }));
    } catch (err) {
      dispatch(showSnackbar({ message: "Something went wrong", severity: "error" }));
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="form">
      <div className="form__fields">
        <AuthFormGroup
          label="Old Password"
          error={errors.oldPassword}>
          <Input
            fullWidth
            type="password"
            {...register("oldPassword", { required: true })}
            placeholder="Enter old password"
          />
        </AuthFormGroup>
        <AuthFormGroup
          label="New Password"
          error={errors.newPassword}>
          <Input
            fullWidth
            {...register("newPassword", { required: true })}
            type={showPassword ? "text" : "password"}
            placeholder="Enter new password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((prev) => !prev)}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </AuthFormGroup>
        <AuthFormGroup
          label="Confirm New Password"
          error={errors.confirmNewPassword}>
          <Input
            fullWidth
            {...register("confirmNewPassword", {
              required: true,
              validate: (val) => {
                if (watch("newPassword") !== val) {
                  return "* Your passwords do no match";
                }
              },
            })}
            type="password"
            placeholder="Confirm new password"
          />
        </AuthFormGroup>
      </div>
      <div className="c-dialog__btns">
        <Button
          appearance="ghost"
          onClick={close}
          className="outlined-btn">
          Cancel
        </Button>
        <Button
          appearance="primary"
          type="submit"
          loading={isLoading}
          className="outlined-btn black-btn">
          Change Password
        </Button>
      </div>
    </form>
  );
}
