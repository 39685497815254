import UserIdLayout from "../components/users/UserIdLayout";
import Layout from "../components/Layout";
import VideocamIcon from "@mui/icons-material/Videocam";
import { NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useState, useEffect } from "react";
import { instance } from "../routes/axios";
import { showSnackbar } from "../store/snackbarSlice";
import DrillsIdNav from "../components/drills/DrillsIdNav";
import { formatStringClass, formatToTwoDecimals, getScoreStatusClass } from "../components/users/helpers";
import { Skeleton } from "@mui/material";
import { Tooltip } from "@mui/material";

function DrillScoreItem({ data, title }) {
  return (
    <Tooltip
      placement="top"
      title={data.description}>
      <div className="drillsOverview__item">
        <p className="text-greyTitle flex items-center justify-between">
          {title} <span className={`skills__status ${getScoreStatusClass(data.scores.raw_score)}`}>{formatStringClass(getScoreStatusClass(data.scores.raw_score))}</span>
        </p>
        <div className="flex items-center justify-between">
          <p className="drillsOverview__item-text font-medium">{formatToTwoDecimals(data.scores.raw_score)}%</p>
          <div className="flex items-center">
            <span className="skills__status mr">5 of 5</span>
            <VideocamIcon />
          </div>
        </div>
      </div>
    </Tooltip>
  );
}
function DrillHighlightItem() {
  return (
    <div className="">
      <img
        className="drillsOverview__video1"
        src="/images/avatar.png"
        alt=""
      />
      <div className="flex items-center justify-between">
        <p className="skills__item-text font-medium">13:57</p>
        <span className="skills__status bad">Bad</span>
      </div>
    </div>
  );
}

export default function DrillHistoryIdPage() {
  const { drillId, id } = useParams();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [scores, setScores] = useState([]);
  const [drill, setDrill] = useState(null);

  // const fetchData = useCallback(async () => {
  //   if (!user) return;
  //   setLoading(true);
  //   try {
  //     const videos = await instance.post("/drill-upload-details", {
  //       _id: id,
  //     });
  //     const analysis = await instance.post("/drill-analysis", {
  //       userId: id,
  //     });
  //     const scores = await instance.post("/drill-scores", {
  //       userId: id,
  //     });
  //     const foundDrillAnalysis = analysis.data.data.find((x) => x.videoUploadsId === drillId);
  //     const foundScores = scores.data.data.find((x) => x.videoUploadsId === drillId);
  //     const foundVideo = videos.data.data.data.find((x) => x._id === drillId);
  //     setDrill(foundVideo);
  //     console.log(foundVideo);
  //     setData(foundDrillAnalysis);
  //     setScores(foundScores);
  //   } catch (err) {
  //     console.error(err);
  //     dispatch(showSnackbar({ message: "Something went wrong", severity: "error" }));
  //   } finally {
  //     setLoading(false);
  //   }
  // }, [id, dispatch, drillId, user]);

  // useEffect(() => {
  //   fetchData();
  // }, [fetchData]);

  // console.log(scores);
  // console.log(data);
  // console.log(scores);

  return (
    <Layout>
      <UserIdLayout
        isDrill
        name={drill?.gameType || "N/A"}>
        <DrillsIdNav
          setDrill={setDrill}
          setScores={setScores}
          setData={setData}
          setLoading={setLoading}
        />
        <div className="drillsOverview">
          <div className="drillsOverview__block main-content-px">
            <div>
              <p className="drillsOverview__block-small">Total Drill Score</p>
              <p className="drillsOverview__block-value">{formatToTwoDecimals(scores?.total_score || 0)}%</p>
            </div>
            <div className="drillsOverview__block-list">
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  height={118}
                />
              ) : (
                data?.performance_metrics &&
                Object.entries(scores.areas).map(([key, value]) => (
                  <DrillScoreItem
                    key={key}
                    title={key}
                    data={value}
                  />
                ))
              )}
            </div>
          </div>
          <div className="drillsOverview__block main-content-px">
            <div>
              <p className="drillsOverview__block-small">Uploaded video</p>
              <p className="drillsOverview__block-value">1</p>
            </div>
            <div className="drillsOverview__video">
              {isLoading && (
                <>
                  <Skeleton
                    variant="rectangular"
                    height={118}
                  />
                  <Skeleton />
                  <Skeleton width="60%" />
                </>
              )}
              {!isLoading && drill && (
                <video
                  className="drillsOverview__video"
                  controls>
                  <source
                    src={`https://dev-admin-ui.aim-football.com/${drill?.filepath}`}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
          </div>
          <div className="drillsOverview__block main-content-px">
            <div>
              <p className="drillsOverview__block-small">Highlights</p>
              <p className="drillsOverview__block-value">7</p>
            </div>
            <div className="drillsOverview__block-list">
              {/* <DrillHighlightItem />
              <DrillHighlightItem />
              <DrillHighlightItem />
              <DrillHighlightItem />
              <DrillHighlightItem />
              <DrillHighlightItem />
              <DrillHighlightItem />
              <DrillHighlightItem /> */}
            </div>
          </div>
          <div className="drillsOverview__block main-content-px">
            <div>
              <p className="drillsOverview__block-small">Drill Attempts</p>
              <p className="drillsOverview__block-value">{drill?.processing_attempts}</p>
            </div>
            <div className="drillsOverview__block-list">
              {/* <DrillScoreItem />
              <DrillScoreItem />
              <DrillScoreItem /> */}
            </div>
          </div>
        </div>
      </UserIdLayout>
    </Layout>
  );
}
