import { useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { instance } from "../routes/axios";
import { showSnackbar } from "../store/snackbarSlice";
import Layout from "../components/Layout";
import { InputGroup, Input } from "rsuite";
import useDebounce from "../helpers/useDebounce";
import { useNavigate } from "react-router-dom";
import { formatDateTime, formatStringId } from "../components/users/helpers";
import CTablePagination from "../components/table/CTablePagination";
import TableBodyLoading from "../components/table/TableBodyLoading";
import { allDrillsTableHead } from "../components/users/helpers";
import CTableHead from "../components/table/CTableHead";
import ManageVideosTableToolbar from "../components/manage-videos/ManageVideosTableToolbar";
import CTableRowCheckbox from "../components/table/CTableRowCheckbox";

export default function ManageVideosPage() {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [orderBy, setOrderBy] = useState("desc");
  const [sortBy, setSortBy] = useState("uploadDate");
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchData = useCallback(
    async (search = "") => {
      setLoading(true);
      try {
        const res = await instance.post("/fetch-drills", {
          page,
          limit,
          search,
          orderBy,
          sortBy,
        });
        setData(res.data.data.videoUpload);
        setTotalCount(res.data.data.totalCount);
      } catch (err) {
        console.error(err);
        dispatch(showSnackbar({ message: "Something went wrong", severity: "error" }));
      } finally {
        setLoading(false);
      }
    },
    [dispatch, page, limit, orderBy, sortBy]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const debouncedFetchData = useDebounce(fetchData, 500);

  const handleSearchChange = (value) => {
    setSearch(value);
    debouncedFetchData(value);
  };

  function handleNavigate(row) {
    navigate(`/users/${row.user}/drill-history/${row._id}`);
  }

  return (
    <Layout>
      <div className="main-content-px">
        <p className="main-content-title">Manage videos</p>
        <div className="filters-block filters-block-users">
          <div className="filters-block-item">
            <p className="filter-label">Search</p>
            <InputGroup size="lg">
              <Input
                placeholder="Search video by user ..."
                onChange={(value) => handleSearchChange(value)}
              />
            </InputGroup>
          </div>
        </div>
      </div>
      <ManageVideosTableToolbar
        selected={selected}
        fetchData={fetchData}
        setSelected={setSelected}
      />
      <TableContainer>
        <Table sx={{ minWidth: 900 }}>
          <CTableHead
            setOrderBy={setOrderBy}
            setSortBy={setSortBy}
            data={data}
            headData={allDrillsTableHead}
            orderBy={orderBy}
            sortBy={sortBy}
            rowCount={data.length}
            setSelected={setSelected}
            numSelected={selected.length}
          />
          <TableBody>
            {isLoading ? (
              <TableBodyLoading colSpan={allDrillsTableHead.length + 1} />
            ) : (
              data.map((row, index) => {
                const isItemSelected = selected.some((obj) => obj._id === row._id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    key={row._id}
                    onClick={() => handleNavigate(row)}
                    role="checkbox"
                    tabIndex={-1}
                    hover
                    style={{ cursor: "pointer" }}>
                    <CTableRowCheckbox
                      _id={row._id}
                      data={data}
                      labelId={labelId}
                      isItemSelected={isItemSelected}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <TableCell>{formatStringId(row._id)}</TableCell>
                    <TableCell>{row.gameType || "N/A"}</TableCell>
                    <TableCell>{formatDateTime(row.uploadDate)}</TableCell>
                    <TableCell>
                      <p className={`drill-status ${row.status.toLowerCase()}`}>{row.status}</p>
                    </TableCell>
                    <TableCell>{row.userId}</TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CTablePagination
        setPage={setPage}
        setLimit={setLimit}
        totalCount={totalCount}
        limit={limit}
        page={page}
      />
    </Layout>
  );
}
