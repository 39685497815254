import { Toolbar } from "@mui/material";
import { Button } from "rsuite";
import { instance } from "../../routes/axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../store/snackbarSlice";
import CustomDialogIcon from "../libs/CustomDialogIcon";
import DeleteIcon from "@mui/icons-material/Delete";

export default function ManageVideosTableToolbar({ selected, fetchData, setSelected }) {
  const [isLoading, setLoading] = useState(false);
  const [dialog, setDialog] = useState(false);
  const dispatch = useDispatch();

  const dialogContent = {
    title: "Delete drills",
    icon: <DeleteIcon />,
    text: "Are you sure you want to delete next list of drills?",
    buttonText: "Delete",
    buttonAction: deleteUsers,
  };

  async function deleteUsers() {
    try {
      const res = await instance.post("/delete-drills", { _ids: selected.map((x) => x._id) });
      dispatch(showSnackbar({ message: `${selected.length} drill(s) have been deleted`, severity: "success" }));
      setSelected([]);
      fetchData();
      setDialog(false);
    } catch (err) {
      dispatch(showSnackbar({ message: "Something went wrong", severity: "error" }));
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {selected.length > 0 && (
        <div className="main-content-px">
          <Toolbar className="usersTableToolbar">
            <p className="title-sm">{selected.length} selected</p>
            <div className="usersTableToolbar__btns">
              <Button
                appearance="ghost"
                onClick={() => setDialog(true)}
                loading={isLoading}
                className="outlined-btn red-outlined-btn">
                Delete
              </Button>
            </div>
          </Toolbar>
          <CustomDialogIcon
            open={dialog}
            close={() => setDialog(false)}
            content={dialogContent}
            isLoading={isLoading}>
            <div className="usersTableToolbar__dialogList">
              {selected.map((x) => {
                return (
                  <p
                    key={x._id}
                    className="">
                    {x.gameType} - #{x._id}
                  </p>
                );
              })}
            </div>
          </CustomDialogIcon>
        </div>
      )}
    </>
  );
}
