import { Input, Button, Tag } from "rsuite";
import { useForm, Controller } from "react-hook-form";
import AuthFormGroup from "../auth/AuthFormGroup";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { instance } from "../../routes/axios";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../store/snackbarSlice";

const scoringKeys = {
  Dribbling: ["Angle of Ball Return", "Ball Distance from Foot", "No of Touches", "Speed to Complete"],
  "First Touch": ["Dribbling 1st Touch Angle Difference", "Dribbling 1st Touch Distance", "Passing 1st Touch Distance"],
  Passing: ["Angle of Ball Return", "Average Touches", "Ball Control Distance", "Ball Speed Return", "Passing Duration"],
};

export default function MetricsUploadForm({ close, fetchData, isEdit, _id, editValues }) {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: editValues
      ? editValues
      : {
          gameType: "",
          fileName: "",
          name: "",
          rules: {
            Dribbling: {},
            "First Touch": {},
            Passing: {},
          },
        },
  });

  async function onSubmit(data) {
    const formattedData = { ...data };
    if (formattedData.rules?.Dribbling?.Scoring?.["No of Touches"]) {
      formattedData.rules.Dribbling.Scoring["No. of Touches"] = formattedData.rules.Dribbling.Scoring["No of Touches"];
      delete formattedData.rules.Dribbling.Scoring["No of Touches"];
    }

    setLoading(true);
    try {
      const res = isEdit ? await instance.put(`/scoring-metrics/${_id}`, formattedData) : await instance.post("/scoring-metrics", formattedData);
      fetchData();
      close();
      dispatch(showSnackbar({ message: `Scoring metric has been successfully added`, severity: "success" }));
    } catch (err) {
      dispatch(showSnackbar({ message: err.response.data.message || "Something went wrong", severity: "error" }));
    } finally {
      setLoading(false);
    }
  }

  function onKeyDown(e, ruleName, keyValue) {
    const formKey = `rules.${ruleName}.Scoring[${keyValue}].Values`;
    if (e.key === "Enter") {
      const pair = e.target.value.trim().split("/").map(Number);
      if (pair.length === 2 && !pair.some(isNaN)) {
        const currentValues = getValues(formKey);
        if (currentValues) {
          setValue(formKey, [...currentValues, pair]);
        } else {
          setValue(formKey, [pair]);
        }
        e.target.value = "";
      }
    }
  }

  return (
    <div className="form scoring__form prompts__form">
      <div className="form__fields">
        <AuthFormGroup label="Game Type">
          <Controller
            name="gameType"
            control={control}
            render={({ field }) => (
              <Input
                value={field.value || ""}
                onChange={field.onChange}
              />
            )}
          />
        </AuthFormGroup>

        <AuthFormGroup label="File Name">
          <Controller
            name="fileName"
            control={control}
            render={({ field }) => (
              <Input
                value={field.value || ""}
                onChange={field.onChange}
              />
            )}
          />
        </AuthFormGroup>
        <AuthFormGroup label="Name">
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input
                value={field.value || ""}
                onChange={field.onChange}
              />
            )}
          />
        </AuthFormGroup>
        {Object.keys(scoringKeys).map((ruleName) => (
          <div
            key={ruleName}
            className="metricsRule">
            <p className="metricsRule__title">{ruleName}</p>
            <div className="form__fields">
              <AuthFormGroup label="Description">
                <Controller
                  name={`rules.${ruleName}.Description`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      as="textarea"
                      rows={2}
                      value={field.value || ""}
                      onChange={field.onChange}
                    />
                  )}
                />
              </AuthFormGroup>
              <AuthFormGroup label="Overall Weighting">
                <Controller
                  name={`rules.${ruleName}.Overall Weighting`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      value={field.value || ""}
                      onChange={field.onChange}
                    />
                  )}
                />
              </AuthFormGroup>
              <div>
                <p className="auth__form-label">Scoring</p>
                {scoringKeys[ruleName].map((key) => (
                  <div
                    className="scoringItem"
                    key={key}>
                    <p className="scoringItem__title">{key}</p>
                    <div className="scoringItem__fields form__fields">
                      <AuthFormGroup label="Description">
                        <Controller
                          name={`rules.${ruleName}.Scoring[${key}].Description`}
                          control={control}
                          render={({ field }) => (
                            <Input
                              as="textarea"
                              rows={2}
                              value={field.value || ""}
                              onChange={field.onChange}
                            />
                          )}
                        />
                      </AuthFormGroup>
                      <AuthFormGroup label="Weighting">
                        <Controller
                          name={`rules.${ruleName}.Scoring[${key}].Weighting`}
                          control={control}
                          render={({ field }) => (
                            <Input
                              value={field.value || ""}
                              onChange={field.onChange}
                            />
                          )}
                        />
                      </AuthFormGroup>
                      <AuthFormGroup label="Values (Enter pairs of numbers ex. key / value, 1 / 2, 4.95 / 4)">
                        <Input onKeyDown={(e) => onKeyDown(e, ruleName, key)} />
                      </AuthFormGroup>
                      <div>
                        {watch(`rules.${ruleName}.Scoring[${key}].Values`)?.map((pair, index) => (
                          <Tag
                            style={{ background: "white", margin: "5px" }}
                            key={index}
                            onClick={() => {
                              const updatedValues = getValues(`rules.${ruleName}.Scoring[${key}].Values`).filter((_, i) => i !== index);
                              setValue(`rules.${ruleName}.Scoring[${key}].Values`, updatedValues);
                            }}>
                            [{pair[0]}, {pair[1]}]
                            <CloseIcon />
                          </Tag>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}

        <Button
          onClick={handleSubmit(onSubmit)}
          appearance="primary"
          className="outlined-btn black-btn min-width">
          Submit
        </Button>
      </div>
    </div>
  );
}
