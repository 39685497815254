import { Menu, NotificationsNone } from "@mui/icons-material";
import { instance } from "../routes/axios";
import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAdminData } from "../store/adminSlice";
import { getAdminNameLetters } from "./users/helpers";

export default function Header({ handleDrawer }) {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const admin = useSelector((state) => state.admin);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const email = localStorage.getItem("email");
    try {
      const res = await instance.post("/fetch-admin-details", { email });
      dispatch(setAdminData(res.data));
      setLoading(false);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!admin) {
      fetchData();
    }
  }, [admin]);

  return (
    <header className="header main-content-px">
      <div className="header__left">
        <button
          className="header__menuBtn"
          onClick={() => handleDrawer((prev) => !prev)}>
          <Menu />
        </button>
        <img
          src="https://dev-admin-restapi.aim-football.com/images/logo-white.svg"
          alt="logo"
        />
      </div>
      <div className="header__right">
        {/* <p className="header__search">search</p> */}
        <NotificationsNone />
        <div className="header__user">
          <div className="header__user-icon">{getAdminNameLetters(admin?.firstName, admin?.lastName)}</div>
        </div>
      </div>
    </header>
  );
}
