import { Message } from "rsuite";

export default function EmptyDataMessage() {
  return (
    <Message
      showIcon
      type="info">
      No data available for the selected section
    </Message>
  );
}
