import Layout from "../components/Layout";
import UserIdLayout from "../components/users/UserIdLayout";
import { UsersStatsItem } from "./UsersPage";
import { DateRangePicker } from "rsuite";
import MultipleAutocomplete from "../components/libs/MultipleAutocomplete";
import { useEffect, useCallback, useState } from "react";
import { instance } from "../routes/axios";
import { useSelector } from "react-redux";

const typeOptions = [
  { label: "Amateur", value: "Amateur" },
  { label: "Intermediate", value: "Intermediate" },
  { label: "Proficient", value: "Proficient" },
  { label: "Expert", value: "Expert" },
];

export function HlightsItem({ x }) {
  return (
    <div className="hlights__item">
      <video
        autoPlay
        muted
        type="video/mp4"
        loop>
        <source src={x?.videoUrl} />
      </video>
      {/* <img
        src="/images/avatar.png"
        alt=""
      /> */}
      <p className="font-medium">Drill name</p>
      <p className="text-greyTitle">{x?.uploadDate}</p>
    </div>
  );
}

export default function HighlightsPage() {
  const [typesSelected, setTypes] = useState([]);
  const user = useSelector((state) => state.user);

  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  // const fetchData = useCallback(async () => {
  //   setLoading(true);
  //   try {
  //     const res = await instance.post("/drill-upload-details", { _id: user?._id });
  //     setData(res.data);
  //     setLoading(false);
  //   } catch (err) {
  //     console.log(err);
  //   } finally {
  //     setLoading(false);
  //   }
  // }, [user]);

  // useEffect(() => {
  //   fetchData();
  // }, [fetchData]);

  return (
    <Layout>
      <UserIdLayout>
        <div className="usersStats main-content-px grid-cols-3 py-15">
          <UsersStatsItem
            title="Total highlights"
            value={7}
          />
          <UsersStatsItem
            title="Good"
            value={65}
          />
          <UsersStatsItem
            title="Bad"
            value={3}
          />
        </div>
        <div className="filters-block main-content-px">
          <MultipleAutocomplete
            options={typeOptions}
            label="Type"
            onChange={(event, newValues) => setTypes(newValues)}
          />
          <div>
            <p className="filter-label">Date</p>
            <DateRangePicker size="lg" />
          </div>
        </div>
        <div className="main-content-px activity">
          <div className="hlights__list">
            {data.map((x) => {
              return (
                <HlightsItem
                  key={x._id}
                  x={x}
                />
              );
            })}
          </div>
        </div>
      </UserIdLayout>
    </Layout>
  );
}
