import { Avatar } from "rsuite";
import { Button } from "rsuite";
import EditIcon from "@mui/icons-material/Edit";
import CopyButton from "../utils/CopyButton";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAdminNameLetters } from "../users/helpers";
import { useMediaQuery, useTheme } from "@mui/material";

export default function SettingsProfile() {
  const navigate = useNavigate();
  const admin = useSelector((state) => state.admin);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  function EditProfileLink() {
    return (
      <Button
        onClick={() => navigate("/settings/edit-profile")}
        appearance="primary"
        className="black-btn outlined-btn mt-18"
        startIcon={<EditIcon />}>
        {!isMobile && <p>Edit Profile</p>}
      </Button>
    );
  }

  return (
    <div className="page-block page-block-pd">
      <p className="title-sm text-greyTitle">Admin Details</p>
      <div className="settings__profile">
        <div className="settings__profile-avatar">
          <Avatar size="xl">{getAdminNameLetters(admin?.firstName, admin?.lastName)}</Avatar>
          {isMobile && <EditProfileLink />}
        </div>
        <div className="settings__profile-content">
          <div>
            <p className="settings__profile-name">
              {admin.firstName} {admin.lastName}
            </p>
            <p className="settings__profile-position">{admin.role}</p>
          </div>
          <div className="settings__profile-email">
            <p>{admin.email}</p> <CopyButton msg={admin.email} />
          </div>
        </div>
      </div>
      {!isMobile && <EditProfileLink />}
    </div>
  );
}
