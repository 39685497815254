import Layout from "../components/Layout";
import ConfigurationsNav from "../components/configurations/ConfigurationsNav";

export default function ConfigurationsPage() {
  return (
    <Layout>
      <ConfigurationsNav />
    </Layout>
  );
}
