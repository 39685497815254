import { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Box from "@mui/material/Box";
import Header from "./Header";

//todo: mui

export default function Layout({ children, bodyColor }) {
  const [openDrawer, setOpenDrawer] = useState(() => {
    const savedDrawer = localStorage.getItem("drawer");
    return savedDrawer === "true";
  });

  useEffect(() => {
    document.body.style.background = bodyColor ? bodyColor : "#fff";
  }, [bodyColor]);

  function handleDrawer() {
    setOpenDrawer((prev) => {
      const newValue = !prev;
      localStorage.setItem("drawer", newValue);
      return newValue;
    });
  }

  return (
    <div>
      <Header handleDrawer={handleDrawer} />
      <Box
        sx={{ display: "flex" }}
        className="main-layout">
        <Sidebar
          close={handleDrawer}
          open={openDrawer}
        />
        <Box
          component="main"
          sx={{ flexGrow: 1 }}>
          <div className="main-content">{children}</div>
        </Box>
      </Box>
    </div>
  );
}
