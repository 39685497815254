import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import CheckIcon from "@mui/icons-material/Check";
import RemoveIcon from "@mui/icons-material/Remove";

export default function CTableHeadCheckbox({ numSelected, rowCount, onSelectAllClick }) {
  return (
    <TableCell padding="checkbox">
      <Checkbox
        indeterminate={numSelected > 0 && numSelected < rowCount}
        indeterminateIcon={
          <div className="custom-checkbox">
            <RemoveIcon />
          </div>
        }
        checked={rowCount > 0 && numSelected === rowCount}
        onChange={onSelectAllClick}
        icon={<div className="custom-checkbox"></div>}
        checkedIcon={
          <div className="custom-checkbox">
            <CheckIcon />
          </div>
        }
        inputProps={{
          "aria-label": "select all users",
        }}
      />
    </TableCell>
  );
}
