import { configureStore } from "@reduxjs/toolkit";
import snackbarReducer from "./snackbarSlice";
import userReducer from "./userSlice";
import adminReducer from "./adminSlice";

const store = configureStore({
  reducer: {
    snackbar: snackbarReducer,
    user: userReducer,
    admin: adminReducer,
  },
});

export default store;

const user = {
  id: "",
  deactivated: false, // what should we display in figma if user is deactivated and can we reactive him
  deleted: false, // what happens if user is deleted
  name: "",
  country: "",
  email: "",
  gender: "",
  age: "",
  height: "",
  position: "",
  strongFoot: "",
  //are these skills fixed, or can be changed? how we decide if value is very food, average or bad? in ui it has an additional tag
  skills: {
    movement: "",
    speed: "",
    awareness: "",
    ballControll: "",
  },
  rank: "" || 0, // string or number, I do not know exact value
  avgScore: 0, // should be a number?
  notes: [], // array of strings I guess?
  drills: {
    history: [
      {
        name: "",
        results: "" || 0, // string or number? I do not know
        attempts: 0,
        date: "",
        assetUrl: "",
        type: "?", // should be confirmed
        details: [], // should be confirmed what is in expand drill row
      },
    ],
    completedCount: 0, // total count of completed drills
    totalCount: 0, // total count of drills,
    goodCount: 0,
    badCount: 0,
  },
  // maybe last 6-7 users
  similarPlayers: [
    {
      userId: "",
      avatar: "",
      name: "",
      country: "",
      score: 0,
    },
  ],
  activity: [
    {
      id: "",
      name: "",
      date: "",
      completed: false || true,
      type: "?", // do we have type for activity item? Because in figma there is a select to filter by type
    },
  ],
  sessions: {
    history: [
      {
        id: "",
        date: "",
        journey: [""], //array of strings
      },
    ],
    onboarding: "?", // is this value the same like user, or for activity it has separate meaning
    avgTimeSpent: "5 min",
    avgPageCount: 0,
    useOverTime: "+5%",
  },
  coachChats: [
    {
      id: "",
      avatar: "",
      isCoach: true || false,
      name: "",
      message: "",
      date: "",
    },
  ],
};

// * add coach charts search + date
// * add notes for user
// * reset password for user
// * reset state for user
// * deactivate api
// + delete one user (we already have an api to delete users)
