import React, { useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TablePagination, TableHead, TableRow } from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { instance } from "../../routes/axios";
import { showSnackbar } from "../../store/snackbarSlice";
import { useNavigate } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { formatDateTime } from "./helpers";
import TableBodyLoading from "../table/TableBodyLoading";
import EmptyDataMessage from "../table/EmptyDataMessage";

const tableHead = ["Id", "Name", "Date", "Status", ""];

export default function DrillsHistoryTable({ hidePagination }) {
  const [limit, setLimit] = useState(!hidePagination ? 10 : 5);
  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(1);
  };

  const fetchData = useCallback(async () => {
    if (!user?._id) return;
    setLoading(true);
    try {
      const res = await instance.post("/drill-upload-details", {
        _id: user._id,
        page,
        limit,
      });
      setData(res.data.data.data);
      setTotalCount(res.data.data.pagination.totalCount);
    } catch (err) {
      console.error(err);
      dispatch(showSnackbar({ message: "Something went wrong", severity: "error" }));
    } finally {
      setLoading(false);
    }
  }, [user, dispatch, page, limit]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  function handleNavigate(row) {
    navigate(`/users/${user._id}/drill-history/${row._id}`);
  }

  return (
    <>
      {data.length > 0 ? (
        <TableContainer>
          <Table
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
              },
            }}>
            <TableHead>
              <TableRow>
                {tableHead.map((x) => {
                  return <TableCell key={x}>{x}</TableCell>;
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableBodyLoading colSpan={tableHead.length} />
              ) : (
                data.map((row, index) => {
                  return (
                    <TableRow
                      key={row._id}
                      onClick={() => handleNavigate(row)}
                      hover
                      style={{ cursor: "pointer" }}>
                      <TableCell>{row._id}</TableCell>
                      <TableCell width="40%">{row.gameType}</TableCell>
                      <TableCell>{formatDateTime(row.uploadDate)}</TableCell>
                      <TableCell>
                        <p className={`drill-status ${row.status.toLowerCase()}`}>{row.status}</p>
                      </TableCell>
                      <TableCell width="7%">
                        <div className="flex items-center justify-end">
                          <KeyboardArrowRightIcon />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className="main-content-px">
          <EmptyDataMessage />
          <br />
        </div>
      )}
      {!hidePagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalCount}
          rowsPerPage={limit}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
}
