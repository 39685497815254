import { Link } from "react-router-dom";
import AuthBackground from "../components/auth/AuthBackground";
import AuthHeading from "../components/auth/AuthHeading";
import { Input } from "@mui/material";
import { useState } from "react";
import AuthFormGroup from "../components/auth/AuthFormGroup";
import { useForm } from "react-hook-form";
import SuccessIcon from "../components/icons/SuccessIcon";
import AuthSuccess from "../components/auth/AuthSuccess";
import { Button } from "rsuite";

export default function ForgotPasswordPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [email, setEmail] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  function onSubmit(data) {
    setEmail(data.email);
    setIsSuccess(true);
    reset();
  }

  return (
    <div className="auth">
      <AuthBackground />
      <div className={`auth__block ${isSuccess ? "!grid-rows-none" : ""}`}>
        {!isSuccess && (
          <>
            <AuthHeading
              title="Forgot <br/>Password"
              subtitle="Welcome back! Please enter your details."
            />
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="auth__form">
              <AuthFormGroup
                label="Email"
                error={errors.email}>
                <Input
                  {...register("email", { required: true })}
                  fullWidth
                  placeholder="example@email.com"
                  autoComplete="current-email"
                />
              </AuthFormGroup>
              {/* <p className="error-text">Server error text</p> */}
              <div className="auth__submit-block">
                <Button
                  loading={isLoading}
                  type="submit"
                  className="auth__submit">
                  Send Recovery Link
                </Button>
              </div>
              <div className="text-center">
                <span className="auth__link-text">Remembered password?</span>{" "}
                <Link
                  className="auth__link"
                  to="/">
                  Login
                </Link>
              </div>
            </form>
          </>
        )}

        {isSuccess && (
          <AuthSuccess title="Check your email">
            Please check the email address <span className="text-black">{email}</span> for instruction to reset your password
          </AuthSuccess>
        )}
      </div>
    </div>
  );
}
